import React, { useEffect, useState } from 'react';
import {
  Center,
  Spinner,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalBody,
  ModalCloseButton
} from '@chakra-ui/react';
import { isMadPawsStore } from 'common/util';
import moment from 'moment';
import { getStorage } from 'helpers/general';
import { OrderDetailCtn } from 'styled/OrderStatusPopup.styled';

const OrderStatusPopUp = ({ orderId, setOrderId }) => {
  const [orderStatus, setOrderStatus] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchOrderDetail = async () => {
      try {
        setLoading(true);

        const token = getStorage('_loggedIn');

        if (!token) {
          setError('Failed to fetch order status');
          return;
        }

        const api_url = process.env.GATSBY_BC_API;
        const response = await fetch(`${api_url}/v1/customer/orderStatus/${orderId}`, {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            'authorization-BC': token
          }
        });

        if (!response.ok) {
          setError('Failed to fetch order status');
          return;
        }
        const textResponse = await response.text();
        const data = await JSON.parse(textResponse);
        if (data.message) {
          setError(data.message);
          return;
        }
        setError(null);
        setOrderStatus(data.data);
      } catch (error) {
        setError(
          'Please try again. If the issue persists, feel free to contact our support team for assistance!'
        );
      } finally {
        setLoading(false);
      }
    };

    if (orderId) {
      fetchOrderDetail();
    }
  }, [orderId]);

  const closeModal = () => {
    // const scrollY = window.scrollY;
    setOrderStatus(null);
    setOrderId(null);
    // requestAnimationFrame(() => window.scrollTo(0, scrollY));
  };

  const formatDate = dateString => {
    return dateString ? moment(dateString).format('DD/MM/YYYY') : '';
  };

  return (
    <Modal isOpen={!!orderId} onClose={closeModal} autoFocus={false} isCentered>
      <ModalOverlay />
      <ModalContent
        maxW={'600px'}
        maxH={'80%'}
        minW={'300px'}
        borderRadius={{ base: 0, lg: '12px' }}
      >
        <ModalCloseButton
          top={'16px'}
          right={'16px'}
          color={isMadPawsStore() ? '#6B6B6B' : '#202020'}
        />
        <ModalBody p="0">
          {!loading ? (
            <>
              {error && <OrderDetailCtn>Error: {error}</OrderDetailCtn>}
              {orderStatus && (
                <OrderDetailCtn>
                  <div className="title">Order status:</div>
                  <div className="content">
                    {orderStatus && (
                      <>
                        <div className="group">
                          <div className="label">Created date: </div>
                          <div className="value">{formatDate(orderStatus.dateCreated)}</div>
                        </div>
                        <div className="group">
                          <div className="label">Shipped date:</div>
                          <div className="value">{formatDate(orderStatus.dateShipped)}</div>
                        </div>
                        <div className="group">
                          <div className="label">Print date</div>
                          <div className="value">{formatDate(orderStatus.metadata.printedAt)}</div>
                        </div>
                        <div className="group">
                          <div className="label">File in Phamacy:</div>
                          <div className="value">
                            {formatDate(orderStatus.metadata.fileInPhamacy)}
                          </div>
                        </div>
                        <div className="group">
                          <div className="label">Dispensed date:</div>
                          <div className="value">
                            {formatDate(orderStatus.metadata.dispensedAt)}
                          </div>
                        </div>
                        <div className="group">
                          <div className="label">Has medication:</div>
                          <div className="value">
                            {orderStatus.metadata.hasMedication ? 'true' : 'false'}
                          </div>
                        </div>
                      </>
                    )}
                  </div>
                </OrderDetailCtn>
              )}
            </>
          ) : (
            <Center>
              <Spinner />
            </Center>
          )}
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};
export default OrderStatusPopUp;
