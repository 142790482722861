import { head, isArray } from 'lodash';
import React, { useContext, useEffect, useState } from 'react';
import CartContext from '../../context/CartProvider';
import { bcApi } from '../../helpers/bigcommerce';
import BillingAddress from '../../components/atoms/BillingAddress/BillingAddress';
import Button from '../../components/atoms/Button/Button';
import CurrencyFormatter from '../../components/atoms/CurrencyFormatter/CurrencyFormatter';
import DateFormatter from '../../components/atoms/DateFormatter/DateFormatter';
import Dialog from '../../components/atoms/Dialog/Dialog';
import Icon from '../../components/atoms/Icon/Icon';
import AccountPageWrapper from '../../components/organisms/AccountPageWrapper/AccountPageWrapper';
import ReturnsForm from '../../components/organisms/ReturnsForm/ReturnsForm';
import { Center, Spinner, useToast, Flex } from '@chakra-ui/react';
import { useInfiniteQuery } from '@tanstack/react-query';
import * as pcStyles from './orders.module.css';
import * as mpsStyles from './orders.mps.module.css';
import { storeStyle, isMadPawsStore } from '../../common/util';
import OrderStatusPopUp from '../../components/molecules/OrderStatusPopup/OrderStatusPopup';
import { useLocation } from '@reach/router';
import moment from 'moment';

const styles = storeStyle({ mpsStyles, pcStyles });
const fetchProductImage = productId => {
  const endpoint = `catalog/products/${productId}/images`;
  return bcApi(endpoint).then(({ response }) => {
    if (isArray(response.data)) {
      const thumb = response.data.filter(a => a.is_thumbnail === true);
      return head(thumb.length ? thumb : response.data);
    }
    return {};
  });
};

const fetchProducts = async orderId => {
  const productsEndpoint = `orders/${orderId}/products`;
  const products = await bcApi(productsEndpoint, 'GET', null, 2).then(({ response, status }) => {
    if (!response || status !== 200) {
      return [];
    }
    return response;
  });

  const promises = products.map(async product => {
    const image = await fetchProductImage(product.product_id);
    return { ...product, image };
  });

  return Promise.all(promises);
};

const fetchShipping = async orderId => {
  const endpoint = `orders/${orderId}/shipping_addresses`;
  const data = await bcApi(endpoint, 'GET', null, 2).then(({ response, status }) => {
    if (status === 200 && response) {
      return response;
    }
    return [];
  });
  return data;
};

const fetchShipments = async orderId => {
  const endpoint = `orders/${orderId}/shipments`;
  const data = await bcApi(endpoint, 'GET', null, 2).then(({ response, status }) => {
    if (status === 200 && response) {
      return response;
    }
    return [];
  });
  return data;
};

const fetchOrders = async ({ customerId, pageParam, orderId = 0 }) => {
  const endpoint = `orders?customer_id=${customerId}&limit=5&page=${pageParam}&sort=date_created:desc`;

  let orders = await bcApi(endpoint, 'GET', null, 2).then(({ response, status }) => {
    if (!response || status !== 200) {
      return [];
    }
    return response;
  });

  if (orderId !== 0) {
    if (!orders.some(order => order.id === Number(orderId)) && pageParam === 1) {
      const orderEndpoint = `orders/${orderId}?customer_id=${customerId}`;
      const singleOrder = await bcApi(orderEndpoint, 'GET', null, 2).then(({ response, status }) =>
        status === 200 ? response : null
      );
      if (singleOrder) {
        orders = [singleOrder, ...orders]; // Add orderId order to the front
      }
    }
    if (pageParam !== 1) {
      orders = orders.filter(order => order.id !== Number(orderId));
    }
  }
  orders = orders.sort((a, b) => new Date(b.date_created) - new Date(a.date_created));

  const promises = orders.map(async order => {
    const products = await fetchProducts(order.id);
    const shipping = await fetchShipping(order.id);
    let shipments = await fetchShipments(order.id);

    if (shipments.length > 0) {
      shipments = Array.from(new Map(shipments.map(s => [s.tracking_number, s])).values());
    }

    return { ...order, products, shipping_addresses: head(shipping), shipments };
  });

  return Promise.all(promises);
};

const Orders = ({ customerId }) => {
  const [activatedId, setActivatedId] = useState(0);
  const [openReturns, toggleOpenReturns] = useState(false);
  const [reorderError, setReorderError] = useState(false);
  const [orderDetailId, setOrderDetailId] = useState(null);
  const cartCtx = useContext(CartContext);
  const addAllToCart = cartCtx && cartCtx.addAllToCart;
  const orderStatusEnable = process.env.GATSBY_ORDER_STATUS_ENABLE === 'true';
  const toast = useToast();
  const { hash } = useLocation();
  const {
    data,
    isFetching,
    isFetched,
    isFetchingNextPage,
    fetchNextPage,
    hasNextPage
  } = useInfiniteQuery({
    queryKey: ['orders', customerId],
    queryFn: ({ pageParam = 1 }) => {
      const orderIdReq = hash ? hash.replace('#', '') : 0;
      const isValidOrderId = /^\d+$/.test(orderIdReq);
      return fetchOrders({ pageParam, customerId, orderId: isValidOrderId ? orderIdReq : 0 });
    },
    refetchOnWindowFocus: false,
    // enabled: false,
    getNextPageParam: (_, pages) => {
      if (pages.at(-1).length > 0) {
        return pages.length + 1;
      } else {
        return undefined;
      }
    }
  });

  const toggleViewOrder = order => () => {
    if (activatedId.id !== order.id) {
      setActivatedId(order.id);
    } else {
      setActivatedId(0);
    }
  };

  const getFirstProductImage = order => {
    if (order) {
      if (order.products && order.products?.length !== 0) {
        const image = head(order.products).image;
        return <img loading="lazy" src={image?.url_thumbnail} alt={image?.description} />;
      }
    }
    return null;
  };

  const reorder = (e, orderData) => {
    e.target.innerText = 'Adding...';
    addAllToCart(orderData.products)
      .then(response => {
        if (typeof window !== 'undefined') {
          window.location = '/confirm-order';
          // window.location = '/confirm-order/';
        }
      })
      .catch(e => {
        if ('title' in e && e.title.indexOf('does not have sufficient stock') > -1) {
          setReorderError(
            'One or more of the products in this order are currently out of stock. Please add products individually.'
          );
        } else if ('title' in e && e.title.indexOf('No products to add') > -1) {
          setReorderError(
            'There are no products above that can be added. For example, gift cards are not able to be reordered.'
          );
        } else {
          setReorderError(
            'An error occurred while trying to add the products from the order to cart. Please add products individually.'
          );
        }
      });
  };

  useEffect(() => {
    if (hash && isFetched) {
      const reqId = Number(hash.replace('#', ''));
      const element = document.getElementById(reqId);
      if (element) {
        element.scrollIntoView();
        setActivatedId(reqId);
      }
    }
  }, [hash, isFetched, data?.pages]);

  useEffect(() => {
    if (typeof hasNextPage !== 'undefined' && !hasNextPage) {
      toast({
        title: 'No more orders',
        description: 'You have no more orders to view',
        status: 'info',
        duration: 5000,
        isClosable: true,
        colorScheme: 'primary',
        position: 'top-right'
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hasNextPage]);

  const clearReorderDialog = () => {
    setReorderError(false);
  };

  function convertToSydneyTime(dateStr) {
    return moment
      .utc(dateStr)
      .add(11, 'hours')
      .format('DD MMM YYYY HH:mm:ss');
  }

  const handleCancel = (event, reason) => {
    if (reason && reason === 'backdropClick') {
      return false;
    }
    setReorderError(false);
  };

  const cognitoFormsLink = () => {
    let cognitoLink =
      'https://www.cognitoforms.com/madpaws1/medicationorderprescriptiondetailscollection';
    if (data?.pages?.length && data?.pages[0]?.[0]) {
      const firstOrder = data?.pages[0]?.[0];
      const dataPrefill = {
        YourDetails: {
          Name: {
            First: firstOrder.billing_address?.first_name,
            Last: firstOrder.billing_address?.last_name
          },
          OrderNumber: firstOrder.id
        }
      };
      cognitoLink = `${cognitoLink}?entry=${encodeURIComponent(JSON.stringify(dataPrefill))}`;
    }
    return cognitoLink;
  };

  return (
    <div className={styles.root}>
      {!isFetching && data.pages.length === 1 && data.pages?.at(-1)?.length === 0 && (
        <span>You have no orders</span>
      )}

      {isFetching && !isFetchingNextPage ? (
        <Center py={8}>
          <Spinner size={'xl'} color="teal" />
        </Center>
      ) : (
        <>
          <h1 className={`${styles.heading} center-title`}>Orders</h1>
          <Flex className={styles.createAccount}>
            <Button level={'tertiary'} target type={'a'} href={cognitoFormsLink()}>
              UPLOAD PRESCRIPTION
            </Button>
          </Flex>
          <div className={`dataTable ${styles.dataTable}`}>
            <div className={styles.tableHeader}>
              <div data-orderid>
                <span>Order #</span>
              </div>
              <div>
                <span>Order Placed</span>
              </div>
              <div>
                <span>Last Update</span>
              </div>
              <div data-status>
                <span>Status</span>
              </div>
              <div data-toggle></div>
            </div>
            {data?.pages?.map((orders, index) => (
              <React.Fragment key={index}>
                {orders.map((order, index) => (
                  <React.Fragment key={index}>
                    {order.status_id > 0 && (
                      <div className={styles.tableRow} key={order.id} id={order.id}>
                        <div
                          className={styles.orderItem}
                          role="presentation"
                          onClick={toggleViewOrder(order)}
                        >
                          <div data-productimage>{getFirstProductImage(order)}</div>
                          <div data-orderid>
                            <div className={styles.bold}>Order #{order.id}</div>
                            <div className={styles.productDetail}>
                              {order.items_total} product
                              {order.items_total > 1 ? 's' : ''} totaling{' '}
                              <CurrencyFormatter
                                currency={order.currency_code}
                                amount={order.total_inc_tax}
                                ignoreTax={true}
                              />
                            </div>
                          </div>
                          <div data-orderplaced>
                            <div className={styles.bold}>Order Placed</div>
                            <DateFormatter date={order.date_created} />
                          </div>
                          <div data-lastupdated>
                            <div className={styles.bold}>Last Update</div>
                            <DateFormatter date={order.date_modified} />
                          </div>
                          <div data-status>
                            <div className={styles.bold}>Status</div>
                            <span>{order.custom_status ?? order.status}</span>
                          </div>
                          <div data-toggle className={activatedId === order.id ? styles.open : ''}>
                            <Icon symbol={isMadPawsStore() ? 'caretDownMps' : 'caretDown'} />
                          </div>
                        </div>

                        {activatedId === order.id && (
                          <div className={styles.orderDetail}>
                            <div className="show">
                              <div className={styles.orderBillingAddress}>
                                <BillingAddress
                                  title="Ship to"
                                  address={order.shipping_addresses}
                                />
                                <BillingAddress title="Bill to" address={order.billing_address} />
                              </div>
                              <div className={styles.orderDetailTable}>
                                {order.products?.map(product => {
                                  return (
                                    <div key={product.product_id} className={styles.orderDetailRow}>
                                      <div>
                                        <img
                                          loading="lazy"
                                          src={product.image?.url_thumbnail}
                                          alt={product.image?.description}
                                        />
                                      </div>
                                      <div>
                                        <div className={styles.bold}>{product.name}</div>
                                        <div className={styles.quantity}>
                                          Quantity: {product.quantity}
                                        </div>
                                        {product.product_options.length > 0 &&
                                          product.product_options.map((option, optionIndex) => (
                                            <div className={styles.quantity} key={optionIndex}>
                                              {option.display_name}:{option.display_value}
                                            </div>
                                          ))}
                                      </div>
                                      <div className={`flex-end ${styles.bold}`}>
                                        <CurrencyFormatter
                                          currency={order.currency_code}
                                          amount={product.total_inc_tax}
                                          ignoreTax={true}
                                        />
                                      </div>
                                    </div>
                                  );
                                })}
                              </div>
                              {order.shipments.length > 0 ? (
                                <div className={styles.shipments}>
                                  <div className={styles.shipmentTitle}>Shipments info:</div>
                                  <div>
                                    {order.shipments.map((shipment, i) => (
                                      <div
                                        className={styles.shipmentBody}
                                        key={`shipment-${shipment.id}`}
                                      >
                                        <div>
                                          <b>Courier Name:</b> {shipment.shipping_method}
                                        </div>
                                        <div>
                                          <b>Date:</b> {convertToSydneyTime(shipment.date_created)}
                                        </div>
                                        <div>
                                          <b>Tracking:</b> {shipment.tracking_number}
                                        </div>
                                      </div>
                                    ))}
                                  </div>
                                </div>
                              ) : null}
                              <div className={styles.summary}>
                                <div className={styles.summaryActions}>
                                  {orderStatusEnable && (
                                    <div className="flex-between pb-3">
                                      <Button
                                        level="ghost"
                                        type="button"
                                        onClick={e => setOrderDetailId(order.id)}
                                      >
                                        Order Status
                                      </Button>
                                    </div>
                                  )}
                                  <div className="flex-between">
                                    <Button
                                      level="ghost"
                                      type="button"
                                      onClick={e => reorder(e, order)}
                                    >
                                      {isMadPawsStore() ? 'Re-order' : 'Reorder'}
                                    </Button>
                                    <Dialog
                                      open={reorderError ? true : false}
                                      title="Unable to reorder"
                                      size="sm"
                                      hideBtnCancel
                                      disableBackdropClick
                                      onOk={() => clearReorderDialog()}
                                      onClose={(event, reason) => handleCancel(event, reason)}
                                    >
                                      {reorderError}
                                    </Dialog>
                                    {/* <Button level="secondary" type="button" onClick={(e) => toggleOpenReturns(!openReturns)}>Return items</Button> */}
                                  </div>
                                  <Dialog
                                    title="Returns request"
                                    hideBtnOk={true}
                                    cancelBtnText="Close"
                                    open={openReturns}
                                    onCancel={() => toggleOpenReturns(false)}
                                  >
                                    <ReturnsForm orderDetails={order} />
                                  </Dialog>
                                </div>
                                <div className={styles.summaryInner}>
                                  <div>
                                    <span className={styles.normal}>Subtotal:</span>
                                    <span className={styles.bold}>
                                      <CurrencyFormatter
                                        currency={order.currency_code}
                                        amount={order.subtotal_inc_tax}
                                        ignoreTax={true}
                                      />
                                    </span>
                                  </div>
                                  {order?.coupon_discount && order?.coupon_discount > 0 && (
                                    <div>
                                      <span className={styles.normal}>Coupon discount:</span>
                                      <span className={styles.bold}>
                                        <CurrencyFormatter
                                          currency={order.currency_code}
                                          amount={order.coupon_discount}
                                          ignoreTax={true}
                                        />
                                      </span>
                                    </div>
                                  )}

                                  {order?.gift_certificate_amount &&
                                    order?.gift_certificate_amount > 0 && (
                                      <div>
                                        <span className={styles.normal}>Gift certificate:</span>
                                        <span className={styles.bold}>
                                          <CurrencyFormatter
                                            currency={order.currency_code}
                                            amount={order.gift_certificate_amount}
                                            ignoreTax={true}
                                          />
                                        </span>
                                      </div>
                                    )}

                                  <div>
                                    <span className={styles.normal}>Shipping:</span>
                                    <span className={styles.bold}>
                                      <CurrencyFormatter
                                        currency={order.currency_code}
                                        amount={order.shipping_cost_inc_tax}
                                        ignoreTax={true}
                                      />
                                    </span>
                                  </div>

                                  <div>
                                    <span className={styles.normal}>GST:</span>
                                    <span className={styles.bold}>
                                      <CurrencyFormatter
                                        currency={order.currency_code}
                                        amount={order.total_tax}
                                        ignoreTax={true}
                                      />
                                    </span>
                                  </div>

                                  <div>
                                    <span className={isMadPawsStore() && styles.total}>
                                      Grand Total:
                                    </span>
                                    <span className={styles.bold}>
                                      <CurrencyFormatter
                                        currency={order.currency_code}
                                        amount={order.total_inc_tax}
                                        ignoreTax={true}
                                      />
                                    </span>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        )}
                      </div>
                    )}
                  </React.Fragment>
                ))}
              </React.Fragment>
            ))}
          </div>

          {hasNextPage && (
            <Center textAlign={'center'}>
              <Button
                disabled={isFetchingNextPage}
                loading={isFetchingNextPage}
                type={'button'}
                level={'tertiary'}
                onClick={fetchNextPage}
              >
                View more
              </Button>
            </Center>
          )}
        </>
      )}
      {orderDetailId && <OrderStatusPopUp orderId={orderDetailId} setOrderId={setOrderDetailId} />}
    </div>
  );
};

const OrdersOutput = () => (
  <AccountPageWrapper metaTitle="Pet Chemist Online - Order Status" title="Orders">
    <Orders />
  </AccountPageWrapper>
);

export default OrdersOutput;
