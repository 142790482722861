/* eslint-disable import/prefer-default-export */
import styled from 'styled-components';
export const OrderDetailCtn = styled.div`
  padding: 20px;

  .title {
    font-weight: 800;
    font-size: 24px;
    margin-bottom: 20px;
  }
  .content {
    margin: 0 12px;
  }
  .group {
    display: flex;
    align-items: baseline;
    & > div {
      flex: 1;
      max-width: 50%;
    }
    & > div:first-child {
      padding-right: 8px;
    }
    & > div:last-child {
      padding-left: 8px;
    }
    .label {
        font-weight: 600;
      font-size: 14px;
      line-height: 35px;
      word-break: break-word;
    }
  }
`;
